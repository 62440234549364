// Card template
.card-template {
  border-radius: 0 0 0 2rem;
  color: $black1;
  border: 1px solid $grey2;
  margin-bottom: 2.4rem;
  @extend .transition;
  position: relative;

  &.eq-height {
    display: flex;
    flex-direction: column;
    height: calc(100% - 1.6rem);
    .card-body {
      flex: 1;
    }
    .card-footer {
      margin-top: auto;
    }
  }
  &.full-height {
    height: 100%;
    margin-bottom: 0;
  }
  &.hoverable {
    cursor: pointer;
    @extend .hoverable;
  }
  &.space-card {
    .card-body {
      .folder-icon {
        height: 4.8rem;
        width: 4.8rem;
        border-radius: 0.8rem;
        background-color: $purple5;
        font-size: 2.8rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $white;
      }
    }
  }
  &:hover {
    .link-arrow-icon {
      opacity: 1;
    }
  }
  .link-arrow-icon {
    position: absolute;
    right: 2.6rem;
    top: 1.8rem;
    color: $grey4;
    font-size: 1.6rem;
    display: inline-flex;
    cursor: pointer;
    @extend .transition;
    opacity: 0;
    &::before {
      @include rotate(-90deg);
    }
  }
  .card-body {
    background: linear-gradient(
      102deg,
      #fbf9ff 0.94%,
      #f5eefe 74.96%,
      rgba(243, 235, 255, 0) 155.42%
    );
    padding: 1.6rem;
    &.has-radius {
      @extend .be-radius;
    }
    .inner-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .tag-container {
      padding: 0 0 1.2rem 0;
      .MuiStack-root {
        .MuiChip-root {
          padding: 0.2rem 0.8rem;
          width: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2rem;
          .MuiChip-label {
            padding: 0;
            color: $white;
            font-size: 1rem;
            font-weight: 500;
            @include line-height(10, 16);
            letter-spacing: 0.1rem;
            text-transform: uppercase;
          }
        }
      }
      &.has-right-content {
        padding: 0 0 0.8rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon-btn-wrap {
          @extend .btn-goal-rounded;
        }
      }
    }
    .folder-icon {
      display: inline-flex;
      margin-bottom: 1rem;
      font-size: 3.2rem;
    }
    .date-container {
      line-height: 1;
      min-height: 1.8rem;
      span {
        color: $black1;
        font-size: 1rem;
        font-weight: 500;
        @include line-height(10, 16);
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        &.has-icon {
          display: flex;
          flex-wrap: wrap;
          .icon {
            display: inline-flex;
            font-size: 1.6rem;
            margin-left: 0.5rem;
          }
        }
      }
    }
    .card-title-container {
      color: $black1;
      font-size: 1.6rem;
      font-weight: 500;
      @include line-height(16, 24);
      margin-bottom: 0.8rem;
      &:last-child {
        margin-bottom: 0;
      }
      &.secondary {
        font-weight: 400;
      }
      .card-name {
        cursor: pointer;
        display: block;
        max-width: 19.8rem;
        margin-bottom: 0.2rem;
        @extend .truncated-text;
        &.buildcard-tile {
          max-width: 18rem;
        }
        &.add-ellipses {
          @extend .truncated-text;
        }
      }
      .sub-heading {
        color: $grey4;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        @include line-height(12, 16);
        letter-spacing: 0.012rem;
        img,
        .icon {
          margin-right: 0.4rem;
        }
      }
      .lead-name {
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: 400;
        letter-spacing: 0.012rem;
        margin-top: 0.8rem;
        .lead-title {
          margin-right: 0.5rem;
          color: $grey5;
        }
      }

      .be-archive::before {
        margin-right: 0.5rem;
        top: 0.3rem;
        position: relative;
      }
    }
    .progress-circle {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4rem;
      cursor: pointer;
      .progress-bar {
        height: 1rem;
      }
      &:hover {
        .credits {
          display: block;
        }
      }
    }
  }
  .card-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid $grey2;
    background: $white;
    border-radius: 0 0 0 2rem;
    &.two-col {
      .content-box {
        width: 50% !important;
      }
    }
    .content-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-right: 1px solid $grey2;
      padding: 1.6rem;
      width: 32%;
      height: 7.2rem;
      background-color: $white;

      &:first-child {
        border-radius: 0 0 0 2rem;
      }
      &:last-child {
        border: 0;
        width: calc(100% - 64%);
      }
      .title {
        color: $grey5;
        font-size: 1rem;
        font-weight: 500;
        @include line-height(10, 16);
        letter-spacing: 0.1rem;
        text-transform: uppercase;
      }
      .count {
        color: $black1;
        font-size: 1.6rem;
        font-weight: 500;
        @include line-height(16, 24);
      }
    }
  }
  &:hover {
    border: 1px solid $purple2;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15);
  }
}

.intereset-map-card {
  border: 0.1rem solid $grey6;
  @extend .be-radius;
  overflow: hidden;
  transition: all ease-in 0.2s;
  .thumb-wrapper {
    height: 14.4rem;
    display: flex;
    justify-content: center;
    align-items: baseline;
    background: linear-gradient(135deg, #f4eefc 0%, #faeeec 100%);
    position: relative;
    overflow: hidden;
    .img-container {
      border: 0.4rem solid $white;
      border-radius: 1.2rem;
      margin-top: 1.4rem;
      width: 9.2rem;
      height: auto;
      max-height: 20.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 11.47px 22.94px 0px #00000026;
      img {
        width: 100%;
        height: auto;
        border-radius: 1.2rem;
      }
    }
    //&.mobile-view {}
    &.desktop-view {
      align-items: center;
      padding: 2rem;
      .img-container {
        margin: 0;
        border-radius: 0.6rem;
        width: 100%;
        border: 0;
        height: auto;
        box-shadow: none;
        max-width: 19.6rem;
        img {
          border-radius: 0.6rem;
          border: 1px solid $grey12;
        }
      }
    }
  }
  .card-details {
    padding: 1.6rem;
    min-height: 14.5rem;
    .title-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.4rem;
      .is-icon-btn {
        visibility: hidden;
        transition: all ease-in 0.2s;
        width: 3.1rem;
        height: 3.1rem;
      }
    }
    h6 {
      margin: 0;
      max-width: calc(100% - 3.6rem);
      @extend .truncated-text;
    }
    p {
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: $trueGrey;
      margin: 0;
    }
    .description-text {
      max-height: 5rem;
      overflow-x: hidden;
      overflow-y: auto;
      @include custom-scroll;
    }
    .credit-duration {
      @extend .no-bullets;
      margin-top: 0.8rem;
      display: flex;
      align-items: flex-start;

      li {
        font-size: 1.2rem;
        line-height: 1.6rem;
        margin-bottom: 0.4rem;
        span {
          font-size: inherit;
        }
        &:first-child {
          padding-right: 0.8rem;
        }
        &:last-child {
          margin: 0;
          border-left: 1px solid $grey2;
          padding-left: 0.8rem;
        }
        .title {
          color: $grey5;
          margin-right: 0.5rem;
        }
      }
      @include bp(xll-max) {
        flex-flow: column;
        li {
          border: 0 !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }
  .card-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1.6rem;
    border-top: 0.1rem solid $grey2;
    .MuiButton-root {
      margin-right: 1.4rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &:hover {
    cursor: pointer;
    border-color: $purple3 !important;
    box-shadow: 0px 4px 16px 0px #00000026;
    .card-details {
      .title-wrap {
        .is-icon-btn {
          visibility: visible;
        }
      }
    }
  }
}

// Enterprise plan card
.enterprise-plan-card {
  background: $white;
  border: 0.1rem solid $purple2;
  border-radius: 0 0 0 2.4rem;
  overflow: hidden;
  transition: all 0.2s ease-out;
  &.selected {
    border: 0.3rem solid $purple;
    box-shadow:
      0 0.8rem 2rem rgba(64, 0, 152, 0.04),
      0 0 0 0.1rem $purple;
    .plan-name-wrapper {
      .plan-selected-icon {
        opacity: 1;
        visibility: visible;
      }
    }
    .MuiButton-root {
      background-color: $green !important;
      color: $black2 !important;
    }
  }
  &.horizontal-card {
    display: flex;
    flex-wrap: wrap;
    padding: 2.4rem;
    align-items: center;
    @include bp(lg-max) {
      padding: 1.5rem;
    }
    @include bp(smd-max) {
      padding: 0;
    }
    &.selected {
      border-width: 0.1rem;
    }
    .plan-name-wrapper {
      width: 49%;
      padding: 1.6rem;
      @extend .be-radius;
      @include bp(smd-max) {
        width: 100%;
        border-radius: 0;
      }
      .plan-icon {
        display: flex;
        justify-content: center;
        height: auto;
        @include bp(smd-max) {
          justify-content: flex-start;
        }
      }
    }
    .plan-detail-wrapper {
      width: 51%;
      padding: 1rem 0.6rem 1rem 3.7rem;
      @include bp(smd-max) {
        width: 100%;
        padding: 2rem;
      }
    }
  }
  &.sm-card {
    .plan-name-wrapper {
      padding: 1.6rem 2.4rem;
      .plan-icon {
        height: 8rem;
        img {
          max-height: 8rem;
        }
      }
      .credit-info {
        .credit-left {
          font-size: 1.4rem;
        }
      }
    }
    .plan-detail-wrapper {
      padding: 1.6rem 2.4rem;
    }
  }
  &.disabled {
    cursor: not-allowed;
    .plan-name-wrapper {
      padding: 2.4rem 2.3rem;
      .plan-icon {
        opacity: 0.3;
      }
    }
    .plan-detail-wrapper {
      .info-body {
        text-align: center;
        h6,
        p {
          color: $grey12;
        }
      }
    }
  }
  &.is-info-card {
    border-color: $purple2;
    &.disabled {
      border-color: $lightPurple;
    }
    .plan-name-wrapper {
      padding: 2.4rem 2.3rem;
      .plan-icon {
        height: 8.4rem;
        justify-content: center;
      }
    }
  }
  &.flex-card {
    display: flex;
    flex-direction: column;
    .plan-detail-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1;
      .info-body {
        margin-bottom: 2.4rem;
      }
      .plan-btn-wrap {
        margin-top: auto;
      }
    }
  }
  .plan-name-wrapper {
    padding: 6rem 2.3rem 2.4rem;
    background-color: $lightPurple;
    position: relative;
    .badge {
      position: absolute;
      left: 2.3rem;
      top: 2.8rem;
      display: inline-block;
      font-size: 1.4rem;
      color: $purple1;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    .plan-selected-icon {
      display: inline-flex;
      position: absolute;
      right: 1.6rem;
      top: 1.6rem;
      font-size: 2.4rem;
      color: $purple;
      opacity: 0;
      visibility: hidden;
      @extend .transition;
    }
    .plan-icon {
      display: flex;
      margin-bottom: 1.4rem;
      height: 12rem;
      width: 12rem;
      align-items: center;
      &:last-child {
        margin: 0;
      }
      img {
        width: 12rem;
      }
    }
    h2,
    .h2 {
      color: $purple1;
      margin-bottom: 0.8rem;
      font-size: 2.8rem;
      @include bp(sm-max) {
        font-size: 2.2rem;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    p {
      font-size: 1.6rem;
      line-height: 2.4rem;
      color: $black1;
    }
    h4 {
      font-size: 1.8rem;
      margin: 0;
    }
  }
  .plan-detail-wrapper {
    padding: 2.4rem;
    .info-body {
      text-align: center;
      h6 {
        margin-bottom: 0.8rem;
      }
      p {
        font-size: 1.6rem;
        @include line-height(16, 24);
        color: $trueGrey;
      }
    }
    .credit-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.4rem;
      .credit-left {
        font-weight: 500;
        align-items: flex-end;
        font-size: 1.8rem;
        .credit-no {
          font-size: 2.4rem;
          @include line-height(32, 36);
          color: $black1;
          margin-right: 0.4rem;
          @include bp(sm-max) {
            font-size: 2.2rem;
          }
        }
      }
      .pricing {
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: $grey4;
        text-align: right;
      }
      span {
        display: block;
      }
    }
    .plan-btn-wrap {
      margin-top: 2.4rem;
      .MuiButton-root {
        @extend .w-100;
        margin-bottom: 1.3rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .white-btn {
        color: $black1;
        border: 0.1rem solid #b388ff;
      }
    }
  }
  .plan-success-outer {
    background: #f3ebff;
    padding: 5.1rem 2.4rem 2.4rem;
    display: flex;
    justify-content: center;
    text-align: center;
    height: 100%;
    .icon {
      display: inline-block;
      max-width: 8.4rem;
      margin-bottom: 1.6rem;
    }
    h6 {
      margin-bottom: 0.8rem;
    }
    p {
      color: $trueGrey;
      font-size: 1.6rem;
      @include line-height(16, 24);
    }
  }
  &:hover {
    // border:0.3rem solid $purple;
    box-shadow:
      0 0.8rem 2rem rgba(64, 0, 152, 0.04),
      0 0 0 0.1rem $purple;
    .MuiButton-root {
      background-color: $green !important;
      color: $black2 !important;
    }
  }
}

// Draft card
.draft-card {
  border: 0.1rem solid $grey2;
  margin-bottom: 2.4rem;
  @extend .be-radius;
  overflow: hidden;
  cursor: pointer;
  @extend .transition;
  background: linear-gradient(
    102deg,
    #fbf9ff 0.94%,
    #f5eefe 74.96%,
    rgba(243, 235, 255, 0) 155.42%
  );
  &:last-child {
    margin-bottom: 0;
  }
  &.active {
    border-color: $purple;
  }
  .draft-top {
    padding: 1.6rem 1.6rem 1.6rem;
    position: relative;
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.8rem;
      .right {
        display: flex;
        align-items: center;
        .icon-btn-wrap {
          .MuiButtonBase-root {
            &.rounded {
              border-radius: 1.2rem !important;
              padding: 0.4rem 0.8rem;
              font-size: 1.2rem;
              height: 2rem;
              width: 2.8rem;
              &::before {
                width: 1.2rem;
              }
            }
          }
        }
      }
    }
    .custom-dropmenu {
      @extend .custom-dropmenu;
    }
    .action-btn {
      margin-left: 0.8rem;
    }
    .custom-tag {
      margin-bottom: 0;
    }
    .updated-date {
      display: block;
      font-size: 1rem;
      line-height: 1.6rem;
      font-weight: 500;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
    }
    h6 {
      margin-bottom: 0.2rem;
    }
    .created-date {
      color: $grey11;
      display: block;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 0.012rem;
    }

    .created-by {
      color: #676b7e;
      font-size: 1.1rem;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.012rem;
      line-height: 1.3333333333;
    }
  }
  .count-row {
    border-top: 0.1rem solid $grey2;
    display: flex;
    background: $white;
    .count-col {
      width: 33.33%;
      padding: 1.6rem;
      border-right: 0.1rem solid $grey2;
      &:last-child {
        border-right: 0;
      }
      .title {
        font-size: 1rem;
        line-height: 1.6rem;
        display: block;
        color: $grey5;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.1rem;
      }
      .count {
        display: block;
        font-weight: 500;
      }
    }
  }
}

// Goal card
.goal-card {
  border: 0.1rem solid $grey2;
  border-top: 0.4rem solid $green;
  padding: 2rem 1.6rem 1.6rem;
  background: $white;
  @extend .be-radius;
  @extend .transition;
  cursor: pointer;
  &.off-track {
    border-top-color: $red1;
    .goal-status {
      &:after {
        background-color: $red1;
      }
    }
  }
  &.on-track {
    border-top-color: #69f0ae;
    .goal-status {
      &:after {
        background-color: #69f0ae;
      }
    }
  }
  &.not-started {
    border-top-color: #c0c3ce;
    .goal-status {
      &:after {
        background-color: #c0c3ce;
      }
    }
  }
  &.progressing {
    border-top-color: #ffd740;
    .goal-status {
      &:after {
        background-color: #ffd740;
      }
    }
  }
  &.accomplished {
    border-top-color: #00c853;
    .goal-status {
      &:after {
        background-color: #00c853;
      }
    }
  }

  .goal-type {
    display: block;
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-bottom: 0.8rem;
  }
  h6 {
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 0.4rem;
  }
  .goal-status {
    position: relative;
    font-size: 1rem;
    line-height: 1.6rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    font-weight: 500;
    padding-right: 1.8rem;
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 1.2rem;
      width: 1.2rem;
      display: block;
      background-color: $green;
      border-radius: 50%;
    }
  }
  .accomplished-kpi {
    font-size: 1.53rem;
    line-height: 2.1rem;
    @include bp(xll-max) {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }
  .progress-wrap {
    margin: 1.758rem 0;
    .MuiBox-root {
      .MuiLinearProgress-root {
        background: $grey6;
        .MuiLinearProgress-bar {
          background: $purple2;
          border-radius: 0.44rem;
        }
      }
    }
  }
}

// Card Tags
.card-tags-wrap {
  margin: 0.8rem 0 0;
  min-height: 2.5rem;
  //display: flex;
  .MuiButton-root.xs-btn.is-icon-btn {
    border: 1px solid $purple2;
    width: 2.8rem;
    height: 2rem;
    border-radius: 1.2rem;
    margin: 0 0.6rem 0.6rem 0;
    padding: 0 0.8rem;
    &.whitebg {
      background: $white;
    }
    .icon {
      width: 1.2rem;
      &::before {
        font-size: 1.2rem;
      }
    }
  }
  .tag-wrapper {
    display: flex;
    align-items: center;
    .tags-container {
      display: inline-flex;
      align-items: center;
      max-width: calc(100% - 2.6rem);
      flex-wrap: wrap;
      .custom-tag {
        border-radius: 1.2rem;
        border: 1px solid $purple2;
        background: $white;
        margin: 0 0.6rem 0.6rem 0;
        &.more-tags {
          cursor: pointer;
        }
      }
      .MuiStack-root {
        height: 2rem;
        display: inline-flex;
        margin-bottom: 0.5rem;
        .MuiChip-root {
          position: relative;
          border-radius: 1.2rem;
          border: 1px solid $purple2;
          background: $white !important;
          font-size: 1rem;
          margin: 0 0.6rem 0 0;
          font-weight: 400;
          height: auto;
          justify-content: flex-start;
          .MuiChip-label {
            padding: 0.2rem 0.8rem;
            min-width: 2.8rem;
            max-width: 16rem;
            @extend .transition;
          }
          .MuiChip-deleteIcon {
            opacity: 0;
            font-size: 0.8rem;
            color: $black1;
            position: absolute;
            right: 0.4rem;
            top: 0.25rem;
            padding: 0.2rem;
            margin: 0;
            line-height: 1;
            display: inline-block;
            // background-color: $lightPurple;
            transition: all ease-in 0.1s;
            border-radius: 1rem;
            &::before {
              font-weight: bold !important;
              font-size: 0.8rem;
            }
            &:hover {
              transform: scale(1.1);
            }
          }
          &:hover {
            &.overflow-visible {
              .MuiChip-label {
                overflow: visible !important;
              }
            }
            .MuiChip-label {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: calc(90%);
            }
            .MuiChip-deleteIcon {
              opacity: 1;
              cursor: pointer;
            }
          }
        }
      }
    }
    .icon-btn-wrap {
      width: 2.8rem;
      height: 2rem;
      padding-top: 0;
      margin-bottom: 0.5rem;
      .MuiButtonBase-root {
        margin: 0;
        height: 2rem;
        width: 2.8rem;
        border-radius: 1.2rem;
        &:focus,
        &:focus-visible,
        &:visited {
          background: inherit;
          transition: none;
          outline: none;
        }
        .icon {
          height: 12px;
          line-height: 1.33;
        }
        &:hover {
          background-color: $white !important;
        }
      }
      &.tag-button {
        margin-top: 0.4rem;
        .MuiButtonBase-root {
          height: 2rem;
          width: 2.8rem;
          background: $lightPurple !important;
        }
      }
    }
  }
  &.list-view {
    display: inline-flex;
    justify-content: center;
  }
}
