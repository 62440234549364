@import '../scss/colors';
@import '../scss/mixins';
@import '../scss/extended-classes';
@import '../scss/break-points';
@import '../scss/common-components';

// Section title
.section-title {
  color: $black1;
  font-size: 2rem;
  font-weight: 500;
  @include line-height(20, 32);
  margin-bottom: 2rem;
}
// truncated name
.truncate-name {
  max-width: 16rem;
  display: inline-block;
  line-height: 1.6rem;
  vertical-align: middle;
  @extend .truncated-text;
}

// side by side count : spaces[with custom tooltip] and Buildcard
.side-by-side-count {
  margin: 0 0 1.6rem 0;
  span {
    &.separator {
      margin: 0 1rem;
      border-right: 1px solid $grey;
    }
  }
  .spaces {
    position: relative;
    &:hover {
      cursor: pointer;
      .custom-tooltip {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
  ul.custom-tooltip {
    background: $white;
    position: absolute;
    top: 100%;
    left: -0.3rem;
    transition: all 0.3s ease-in-out;
    padding: 0;
    margin: 0;
    border: 1px solid $grey6;
    border-radius: 0.8rem;
    box-shadow: 0 0.4rem 1.6rem 0 #00000026;
    opacity: 0;
    transform: scale(0);
    max-height: 18rem;
    width: max-content;
    overflow: hidden;
    overflow-y: auto;
    z-index: 2;
    @include custom-scroll;
    &:hover {
      cursor: default;
    }
    li {
      display: flex;
      align-items: center;
      list-style-type: none;
      padding: 1.2rem 1.6rem;
      border-bottom: 1px solid $grey6;
      color: $black1;
      font-size: 1.2rem;
      @include line-height(12, 16);
      &:last-child {
        border: 0;
      }
      //&:hover { cursor: default; }
      .icon {
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 0.2rem;
        padding: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $purple;
        margin: 0 0.8rem 0 0;
        &::before {
          font-size: 1rem;
          color: $white;
        }
      }
      span {
        max-width: 18rem;
        @extend .truncated-text;
      }
    }
  }
}

// side by side title | spaces
.side-by-side-title {
  display: flex;
  align-items: center;
  margin: 0 0 1.2rem 0;
  h3 {
    color: $black1;
    font-size: 1.6rem;
    font-weight: 500;
    @include line-height(16, 24);
    margin: 0 2.4rem 0 0;
  }
  .separator {
    margin: 0 1rem;
    border-right: 1px solid $grey;
  }
  .spaces {
    position: relative;
    &:hover {
      cursor: pointer;
      .custom-tooltip {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}

// Golas : Business/Company and space goals
.goals-overview-page,
.space-goals-wrapper {
  .page-container {
    .separator {
      border-bottom: 1px solid $grey2;
      height: 1px;
      margin-left: -2.4rem;
      margin-right: -2.4rem;
      padding-bottom: 2.4rem;
    }
    .timeline-graph-wrap {
      margin-top: 2.4rem;
      background: $white;
      padding: 1.6rem 2.4rem 0.4rem;
      border-radius: 0px 0px 0px 20px;
      border: 1px solid $grey2;
      margin-bottom: 2.4rem;
      h4 {
        color: $black1;
        font-size: 2rem;
        font-weight: 500;
        @include line-height(20, 32);
        margin-bottom: 1.6rem;
      }
      .graph-container {
        img {
          width: 100%;
        }
        display: flex;
        &.custom-scrollbar,
        .custom-scrollbar {
          max-height: 36rem;
          overflow-x: hidden;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;
          -ms-overflow-style: -ms-autohiding-scrollbar;
          @include custom-scroll;
        }
        .label-box {
          max-width: 100%;
          @extend .truncated-text;
          .color-dot {
            position: relative;
            top: 0.3rem;
            width: 1.6rem;
            height: 1.6rem;
          }
        }
        h5 {
          font-size: 1.6rem;
          @include line-height(16, 24);
          color: $black1;
          margin: 0;
          font-weight: normal;
          margin-bottom: 1.6rem;
        }
      }
    }
    .goals-container {
      padding: 0.8rem 0 0 0;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -2.4rem;
      &.space {
        padding-top: 2.4rem;
      }

      .goals-left-col {
        padding: 0 2.4rem;
        //width: calc(100% - 31.2rem);
        width: 74.7%;
        @include bp(sm-max) {
          width: 100%;
        }
        .page-filters-wrapper {
          .right-filter {
            @include bp(smd-max) {
              justify-content: flex-start;
            }
            .search-input {
              @include bp(sm-max) {
                width: 53%;
              }
            }
            .purple-btn {
              @include bp(sm-max) {
                min-width: fit-content;
              }
            }
          }
        }
      }
      .goals-right-col {
        padding: 0 2.4rem 0 0;
        //width: 31.2rem;
        width: 25.3%;
        @include bp(sm-max) {
          width: 100%;
          padding: 0 2.4rem;
          margin-top: 3.2rem;
          order: -1;
        }
        .search-wrap {
          margin-bottom: 2.4rem;
        }
        .right-col-section {
          background: #fff;
          border: 0.1rem solid #dfe0e6;
          margin-bottom: 2.4rem;
          padding: 2.4rem;
          border-radius: 0 0 0 2rem;
          .goal-count {
            font-size: 1.4rem;
            font-weight: 500;
            @include line-height(14, 20);
            letter-spacing: 0.014rem;
            margin-bottom: 1.3rem;
          }
          .chart-wrapper {
            text-align: center;
            margin-bottom: 2.4rem;
          }
          .goals-status-wrap {
            .content-row {
              font-size: 1.4rem;
              font-weight: 400;
              @include line-height(14, 20);
              letter-spacing: 0.014rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 1.2rem 0;
              border-bottom: 1px solid $grey2;
              &:last-child {
                border-bottom: 0;
              }
              &.title > div {
                text-transform: uppercase;
                font-size: 10px;
                font-weight: 500;
                @include line-height(10, 16);
                letter-spacing: 0.1rem;
              }
              .content-left {
                position: relative;
                padding-left: 2rem;
                .color-dot {
                  background: $lightPurple;
                  width: 1.2rem;
                  height: 1.2rem;
                  border-radius: 100%;
                  margin-right: 0.8rem;
                  display: inline-flex;
                  position: absolute;
                  left: 0;
                  top: 0.3rem;
                }
              }
              //.content-right {}
            }
          }
        }
      }

      // Sorting
      .secondary-header {
        .page-filters-wrapper {
          margin-bottom: 2.4rem;
          .left-filter {
            display: flex;
            .sort-by {
              margin-bottom: 0;
              .dropdown-text {
                font-size: 1.4rem;
                strong {
                  position: relative;
                  z-index: 3;
                }
              }
              .MuiInputBase-root {
                position: relative;
                top: 0;
                left: -0.8rem;
                z-index: 2;
                .MuiSelect-select {
                  padding-right: 2.4rem;
                }
              }
              .dropdown-wrapper {
                margin-right: 1.2rem;
                .MuiInputBase-root {
                  .MuiSelect-select {
                    padding-right: 2.4rem !important;
                    font-weight: 500;
                    font-size: 1.4rem;
                  }
                }
              }
            }
          }
          .right-filter {
            .is-icon-btn {
              transition: all ease-in 0.2s;
              &:hover {
                background-color: $lightPurple;
                border-color: $purple2;
              }
            }
            .btn-add-goal {
              min-width: 10.8rem;
            }
          }
          &.disabled-filters {
            .left-filter {
              .sort-by {
                pointer-events: none;
                .dropdown-wrapper {
                  .dropdown-text {
                    color: $grey21;
                  }
                  .MuiInputBase-root {
                    .MuiSelect-select {
                      color: $grey21;
                    }
                  }
                }
              }
            }
            .right-filter {
              .is-icon-btn {
                pointer-events: none;
                border-color: $grey22;
                background-color: $grey23;
                color: $grey21;
              }
            }
          }
        }
      }

      // Increased by box
      .incread-by-box {
        border-radius: 0 0 0 2rem;
        border: 1px solid $purple6;
        background: $white;
        padding: 2.4rem;
        margin-bottom: 1.6rem;
        transition: all ease-in 0.2s;
        @include bp(sm-max) {
          padding: 2rem;
        }
        &:hover {
          box-shadow: 0 0.4rem 1.6rem 0 #00000026;
          border: 1px solid $purple2 !important;
          cursor: pointer;
          background: $lightPurple1;
        }

        .title-head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .private {
            border-radius: 2rem;
            background: $purple6;
            padding: 0.4rem 0.8rem;
            margin-left: 1.2rem;
            font-size: 1.2rem;
            font-weight: 500;
            @include line-height(12, 16);
            letter-spacing: 0.012rem;
          }
          h4 {
            font-size: 1.8rem;
            display: flex;
            align-items: center;
            @include line-height(18, 28);
            margin-bottom: 1.2rem;
            max-width: calc(100% - 3rem);
            @extend .truncated-text;
            .icon {
              margin-left: 0.8rem;
              height: 2.4rem;
              cursor: pointer;
            }
          }
          .three-dot-btn {
            @extend .three-dot-btn;
            margin-bottom: 1.5rem;
            margin-right: -1rem;
          }
        }
        .progress-wrap {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 4.8rem;
          @include bp(md-max) {
            display: block;
            .text-content {
              margin-bottom: 1.6rem;
            }
          }
          .text-content {
            font-size: 1.6rem;
            width: 55.33%;
            word-wrap: break-word;
            @include bp(sm-max) {
              width: 100%;
              font-size: 1.5rem;
            }
          }
          .progress-bar {
            width: 39.56%;
            padding-top: 0.6rem;
            margin-right: 1.6rem;
            @include bp(smd-max) {
              width: 100%;
              margin-right: 0;
            }
            .MuiBox-root {
              margin-bottom: 0.8rem;
              .MuiLinearProgress-root {
                height: 0.8rem;
                background: $grey6;
                .MuiLinearProgress-bar {
                  background: $purple2;
                }
              }
            }
            .progress-text {
              font-size: 1.6rem;
              @include bp(sm-max) {
                font-size: 1.5rem;
              }
            }
          }
        }
        .tags-wrapper {
          padding-top: 1.2rem;
          .MuiStack-root {
            .MuiChip-root {
              height: 2.4rem;
              background-color: $lightPurple;
              color: $black1;
              .MuiChip-label {
                min-width: 3rem;
                max-width: 19.8rem;
                text-align: center;
                padding: 4px 8px;
                font-size: 1.2rem;
                font-weight: 500;
                @include line-height(12, 16);
                @extend .truncated-text;
                display: flex;
                align-items: center;
                @include bp(sm-max) {
                  font-size: 1.1rem;
                }
                &:first-letter {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        // box footer
        .box-footer {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 2.4rem;
          .date-box {
            @extend .date-box;
          }
          .status {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-size: 1.2rem;
            font-weight: 500 !important;
            @include line-height(12, 16);
            letter-spacing: 0.1rem;
            .color-dot {
              background: $lightPurple;
              margin-left: 0.8rem;
              width: 1.6rem;
              height: 1.6rem;
              border-radius: 100%;
              display: inline-flex;
              &.left {
                margin-right: 0.8rem;
              }
            }
            img {
              margin-left: 0.8rem;
            }
            .MuiInputBase-root {
              > div > .dot.sm {
                display: none;
              }
              .MuiSelect-select {
                font-weight: 500;
                span {
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        &.on-track {
          border-left: 0.4rem solid #69f0ae;
        }
        &.progressing {
          border-left: 0.4rem solid $yellow2;
        }
        &.off-track {
          border-left: 0.4rem solid $red1;
        }
        &.accomplished {
          border-left: 0.4rem solid $green1;
        }
        &.accomplished2, &.archived, &.disabled {
          .title-head {
            h4 {
              color: $trueGrey;
              .be-archive {
                display: inline-flex;
                align-items: center;
                height: 2rem;              
                margin-left: 1.6rem;
                &::before {
                  height: 1.625rem;
                }
              }
            }
          }
          .progress-wrap
            .progress-bar
            .MuiBox-root
            .MuiLinearProgress-root
            .MuiLinearProgress-bar {
            background: $grey11;
          }
        }
        &.disabled,
        :disabled, 
        &.archived {
          * {
            color: $grey11 !important;
            border-color: #cdcfd8 !important;
          }
        }
      }

      .no-data-wrapper {
        padding: 3.2rem 3.2rem 3.6rem;
        p {
          margin: 0 0 1.6rem 0;
        }
        .btn-add-goals {
          width: 18rem;
          height: 4.8rem;
          background-color: $lightPurple;
        }
      }
    }
  }
}

.goals-detail-page {
  .page-container {
    .main-page-header {
      .page-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .right-filter {
          .button-wrap {
            display: inline-flex;
            align-items: center;
            margin-top: 11px;
            .square-btn-three-dots {
              background: $white;
              border: 1px solid $grey19;
              margin-left: 1.6rem;
            }
          }
        }
      }
    }
    .inner-section-wrap {
      padding-top: 0.8rem;
      &.space-goals {
        padding-top: 2.4rem;
      }
      .tab-context-wrapper {
        display: block;
      }
      .page-filters-wrapper {
        margin-bottom: 1.6rem;
        //.left-filter {}
        .right-filter {
          .button-wrap {
            display: flex;
            align-items: center;
            margin-top: -3.5rem;
            .square-btn-three-dots {
              background: $grey13;
            }
            .MuiButtonBase-root {
              &:first-child {
                margin-right: 1.6rem;
              }
            }
          }
        }
      }
    }
  }
}

// Tab section
.tab-label-wrapper {
  @extend .tab-menu;
}
// Tab context panel
.tab-context-wrapper {
  .section-title {
    font-size: 2.4rem;
    font-weight: 500;
    @include line-height(24, 32);
    letter-spacing: 0.024rem;
    margin: 0 0 2.4rem;
    @include bp(sm-max) {
      font-size: 2.2rem;
    }
  }
  .status-content-wrap {
    padding: 1.6rem 2.4rem 1.6rem 2.4rem;
    display: block;
    background: $white;
    margin: 0 0 2.4rem 0;
    border-top: 1px solid $grey2;
    border: 1px solid $grey2;
    border-radius: 0.8rem;
    @include bp(md-max) {
      flex-wrap: wrap;
    }
    .description-wrap {
      padding: 1.6rem 2rem;
      border-bottom: 1px solid $grey2;
      margin: -1.6rem -2.4rem 1.2rem -2.4rem;
      .title {
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.4rem;
        margin: 0;
      }
      .description-text {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }
    .inner-container {
      display: flex;
      justify-content: space-between;
    }
    .col-wrap {
      display: flex;
      align-items: center;
      @include bp(smd-max) {
        flex-wrap: wrap;
      }
      &:first-child {
        @include bp(md-max) {
          order: 1;
        }
        @include bp(sm-max) {
          flex-wrap: wrap;
        }
      }
      &:last-child {
        @include bp(md-max) {
          order: 2;
        }
        @include bp(smd-max) {
          width: 50%;
        }
        .col {
          text-align: right;
          padding-right: 0;
          @include bp(smd-max) {
            text-align: left;
          }
          @include bp(sm-max) {
            width: 100%;
          }
        }
      }
    }
    .col {
      flex: none;
      border-right: 1px solid $grey2;
      padding: 0 5.8rem 0 2.4rem;
      @include bp(sm-max) {
        padding: 0 3.2rem 0 1.6rem;
        width: 50%;
        border: 0;
        &:nth-child(3) {
          padding-left: 0;
        }
      }
      &:first-child {
        padding-right: 1.6rem;
        padding-left: 0;
        .dropdown-contaier {
          height: 3.6rem;
        }
      }
      &:last-child,
      &.no-border {
        border-right: 0;
      }
      label {
        color: $trueGrey;
        font-size: 1.2rem;
        font-weight: 500;
        @include line-height(12, 16);
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        margin: 0 0 0.4rem;
      }
      .owner-name {
        font-size: 1.6rem;
        font-weight: 500;
        color: $black1;
        @include line-height(16, 24);
        .link-text {
          color: $purple1;
          font-size: 1.4rem;
          text-decoration: underline;
          transition: all ease-in 0.2s;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .link-text {
        color: $purple1;
        font-size: 1.4rem;
        text-decoration: underline;
        transition: all ease-in 0.2s;
        &:hover {
          text-decoration: none;
        }
      }
      .content-wrap {
        display: flex;
        align-items: center;
        &.dropdown-container {
          min-height: 3.8rem;
          min-width: 16.1rem;
        }
        .context-label {
          font-size: 1.6rem;
          font-weight: 500;
          color: $black1;
          @include line-height(16, 24);          
        }

        .select-status {
          color: $black1;
          font-size: 1.2rem;
          font-weight: 500;
          @include line-height(12, 16);          
        }
        .MuiInputBase-root {
          .MuiSelect-select {
            font-size: 1.6rem;
            min-height: auto;
            padding: 0 3.2rem 0 0;
            @include bp(sm-max) {
              font-size: 1.6rem;
              padding-right: 2.5rem !important;
            }
            > .dot.sm {
              display: none;
            }
          }
          fieldset {
            display: none;
          }
        }
      }
    }
  }
  .MuiTabPanel-root {
    padding: 0;
    h4 {
      @extend .section-title;
      font-size: 2.2rem;
    }
    .goal-section {
      margin-bottom: 3.2rem;
      .title-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4 {
          margin: 0;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      &.resources-tab-section {
        // background: $white;
        padding: 2.4rem 4.8rem 2.4rem;
        margin: 0 -4.8rem 3.6rem -4.8rem;
        border-top: 1px solid $purple6;
      }
      .light-purple-btn {
        background: $lightPurple;
      }
      .no-data-wrapper-container {
        margin-top: 2.4rem;
      }
      .no-data-wrapper {
        margin-top: 2.4rem;
        margin-bottom: 2.4rem;
      }
      .kpi-count {
        padding-top: 1.2rem;
        color: $trueGrey;
        font-size: 1.4rem;
        @include line-height(14, 20);
      }
      .graph-wrapper {
        margin-top: 2rem;
        border: 1px solid $grey2;
        border-radius: 0 0 0 2rem;
        overflow: hidden;
        background-color: $white;
        padding: 0 0 1.6rem 0.8rem;
        h4 {
          font-size: 2rem;
          @include line-height(20, 32);
          color: $black1;
          margin: 2.4rem;
        }
        .kpi-performance {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      .separator {
        border: 1px solid $grey2;
        margin-bottom: 4rem;
      }

      .efficiancy-cards-wrapper {
        margin: 2.4rem 0 0rem;
        .card-section {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          .epmty-card-text {
            font-size: 1.6rem;
            margin-bottom: 1.4rem;
            @include line-height(16, 24);
          }
          .card {
            background: $white;
            flex-grow: 1;
            width: calc(33% - 4.8rem);
            max-width: calc(33% - 1rem);
            padding: 2rem 2.4rem 2.4rem;
            margin: 0 2.4rem 2.4rem 0;
            border: 1px solid $purple6;
            border-radius: 0 0 0 2rem;
            @include transition(all ease-in 0.1s);
            &:nth-child(3n) {
              margin-right: 0;
            }
            &.off-track {
              border-top: 4px solid $red1;
            }
            &.progressing {
              border-top: 4px solid $yellow2;
            }
            &.on-track,
            &.not-started {
              padding-top: 2.4rem;
            }
            &:hover {
              cursor: pointer;
              box-shadow: 0 0.4rem 1.6rem 0 #00000026;
              background: $lightPurple1;
              border: 1px solid $purple2;
              h4 {
                color: $black;
              }
            }
            @include bp(md-max) {
              width: calc(50% - 2.4rem);
              max-width: calc(50% - 1rem);
              &:nth-child(even) {
                margin-right: 0;
              }
              &:nth-child(odd) {
                margin-right: 2.4rem;
              }
            }
            @include bp(sm-max) {
              width: 100%;
              max-width: 100%;
              margin-right: 0 !important;
            }
            h4 {
              cursor: pointer;
              color: $black1;
              font-size: 1.6rem;
              @include line-height(16, 24);
              font-weight: 500;
              @extend .truncated-text;
              margin: 0 0 1.6rem 0;
              &:first-letter {
                text-transform: uppercase;
              }
            }
            .count {
              @extend .side-by-side-count;
              .spaces {
                color: $purple1;
              }
            }
            .text-description {
              color: $trueGrey;
              font-size: 1.4rem;
              @include line-height(14, 20);
              letter-spacing: 0.005rem;
              @extend .truncated-text;
            }
            .card-bottom {
              margin: 1.6rem 0 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
              text-transform: uppercase;
              .target-date {
                border: 1px solid $grey18;
                border-radius: 0.2rem;
                padding: 0.3rem 0.6rem;
                color: $black2;
                font-size: 1rem;
                font-weight: 500;
                @include line-height(10, 16);
              }
              .status {
                color: $black1;
                font-size: 1rem;
                font-weight: 500;
                @include line-height(10, 16);
                display: flex;
                align-items: center;
                justify-content: flex-end;
                em,
                img {
                  width: 1.6rem;
                  height: 1.6rem;
                  border-radius: 100%;
                  background-color: $grey2;
                  margin-left: 0.8rem;
                  display: inline-flex;
                }
              }
            }
          }
        }
        .accomplished-cards {
          padding-top: 1.6rem;
          padding-bottom: 1.6rem;
          // @include bp(md-max) {
          //   padding-top: 1.6rem;
          //   padding-bottom: 1.6rem;
          // }
          h3 {
            color: $black1;
            font-size: 2rem;
            font-weight: 500;
            @include line-height(20, 32);
            margin-bottom: 1.2rem;
          }
          //.card-section {}
        }
      }

      // Spaces working toward goal
      .spaces-in-goal-wrapper {
        .title-wrap {
          @extend .side-by-side-title;
          justify-content: space-between;
          .count {
            display: inline-flex;
            margin: 0;
            @extend .side-by-side-count;
          }
        }
        .table-wrapper {
          margin: 0.8rem 0 2.4rem;
          tr {
            td {
              border-bottom: 0;
              background: $white;
              &:first-child {
                padding-left: 1.6rem;
                width: 20.37%;
              }
              &:nth-child(2) {
                width: 17.85%;
              }
              &:nth-child(3) {
                width: 19.26%;
              }
              &:nth-child(4) {
                width: 17.85%;
              }
              &:nth-child(5) {
                width: 17.85%;
              }
              &:last-child {
                width: 6.66%;
                a.underline {
                  text-decoration: underline;
                  &:hover {
                    text-decoration: none;
                  }
                }
              }
            }
          }
          .outer-table {
            .icon {
              transition: all ease-in 0.1s;
              &:hover {
                background: $lightPurple;
                border-radius: 100%;
              }
            }
            .be-down-arrow-1 {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              padding: 0.3rem;
              left: 0;
              top: 0.2rem;
              cursor: pointer;
              position: relative;
              transition: all ease-in 0.1s;
              &::before {
                color: $grey11;
              }
              &.move-up {
                transform: rotate(180deg);
                top: 0.2rem;
              }
            }
            .be-left-arrow {
              cursor: pointer;
              padding-left: 1rem;
            }
            .be-chevron-down {
              font-size: 1rem;
              height: 2rem;
              width: 2rem;
              color: $purple;
              font-weight: 500;
              margin-right: 0.6rem;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              transition: all ease-in 0.2s;
              &::before {
                transform: rotate(-90deg);
                display: inline-flex;
              }
              &.toggle-down::before {
                transform: rotate(0deg);
              }
              &.hidden {
                visibility: hidden;
              }
            }
            .collapse-table-wrap {
              background: transparent;
            }
            .inner-table {
              strong {
                color: $purple;
                padding-left: 4.5rem;
                vertical-align: middle;
                line-height: 14px;
              }
              a {
                vertical-align: middle;
                line-height: 14px;
              }
              tr {
                td {
                  background: transparent;
                  position: relative;
                  // &:nth-child(4) {
                  //   padding-left: 9.4%;
                  //   @include bp(xl-max) {
                  //     padding-left: 7.4%;
                  //   }
                  //   @include bp(xl-max) {
                  //     padding-left:6.4%;
                  //   }
                  // }
                  // &:nth-child(5) {
                  //   padding-left: 5.2%;
                  // }
                  &.warning-col {
                    img {
                      position: absolute;
                      margin-left: 5px;
                      margin-top: 0.2rem;
                    }
                  }
                }
              }
            }
            &.business-goals-table {
              min-width: 86rem;
            }
          }
        }
      }
    }
  }
}

// Welcome to business goal popup
.welcome-to-business-goal-popup {
  padding: 2rem;
  width: 45.6rem;
  background: #fff;
  bottom: 2.4rem;
  left: 8.2rem;
  opacity: 0;
  padding: 2rem;
  position: fixed;
  text-align: left;
  transform: translateY(1rem);
  visibility: hidden;
  z-index: 100;
  border-radius: 0 1.2rem 1.2rem 1.2rem;
  box-shadow: 0 1.2rem 3rem 0 rgba(101, 31, 255, 0.1);
  @include bp(md-max) {
    left: 2.6rem;
  }
  @include bp(sm-max) {
    left: 2rem;
    width: calc(100% - 4rem);
  }
  &.open {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
  img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  .close-menu {
    position: absolute;
    right: 2rem;
    top: 2rem;
    cursor: pointer;
  }
  .inner-container {
    padding: 0.4rem 2rem 3.6rem;
    h4.title {
      margin: 2rem 0 1.2rem 0;
      font-size: 1.8rem;
      font-weight: 500;
      @include line-height(18, 28);
    }
    p {
      margin: 0 0 2.4rem;
    }
    .xs-btn {
      width: 100%;
    }
  }
}
.whats-being-built {
  .title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    @include bp(sm-max) {
      flex-wrap: wrap;
    }
    .left {
      display: flex;
      align-items: center;
      @include bp(sm-max) {
        margin-bottom: 1rem;
      }
      h3 {
        @extend .section-title;
        margin: 0 !important;
      }
      .count {
        margin-left: 2.4rem;
        color: $trueGrey;
        font-size: 1.4rem;
        @include line-height(14, 20);
      }
      .separator {
        border: 1px solid $grey2;
        margin-bottom: 4rem;
        margin-right: 2.4rem;
      }
    }
    .right {
      button {
        background-color: $lightPurple;
        &:hover {
          background-color: $lightPurple;
        }
      }
    }
  }
  .no-data-wrapper {
    margin-top: 1.3rem;
  }
  .grid-view-cards-wrapper {
    margin-top: 1.4rem;
    .data-container {
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      .row {
        margin-left: -0.8rem;
        margin-right: -1.6rem;
        .card-template {
          margin-bottom: 2.4rem;
          margin-right: 0.8rem;
        }
      }
    }
  }
}
// KPI Detail section
.kpi-detail-wrapper {
  margin: 3.2rem 0 2.4rem;
  .title-wrap {
    @extend .side-by-side-title;
    justify-content: flex-start;
    margin-bottom: 3.2rem;
    h3 {
      margin-right: 2.4rem;
    }
    .count {
      margin: 0;
      display: inline-flex;
      @extend .side-by-side-count;
    }
  }
  
  .ac-left {
    display: flex;
    align-items: center;
    .text-lable {
      color: $black1;
      font-size: 2rem;
      font-weight: 500;
      @include line-height(20, 32);
    }
    .be-chevron-down {
      width: 2.4rem;
      height: 2.4rem;
      margin-left: 0.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @extend .transition;
      //&.toggle-down {}
      &.toggle-up {
        transform: rotate(180deg);
      }
      &::before {
        font-size: 1.2rem;
      }
    }
  }

  .MuiAccordion-root {
    background: transparent;
    border: 0;
    box-shadow: none;
    &::before {
      display: none;
    }
    .MuiAccordionSummary-root {
      //&.Mui-expanded {}
      min-height: 3.2rem;
      padding: 0;
      margin-bottom: 1.2rem;
      display: inline-flex;
      .MuiAccordionSummary-content {
        //&.Mui-expanded {}
        margin: 0;
      }
    }
    .MuiCollapse-root {
      .MuiCollapse-wrapper {
        .MuiCollapse-wrapperInner {
          .MuiAccordionDetails-root {
            padding: 0;
          }
        }
      }
    }
  }
}

// Create business goal modal
.business-goal-modal {
  .MuiDialog-container {
    .MuiPaper-root {
      border-radius: 1.6rem !important;
      border: 1px solid $grey2;
      background: $white;
      box-shadow: 0 0.4rem 1.6rem 0 rgba(0, 0, 0, 0.15);
    }
  }
  .business-goal-body-wrap {
    padding: 2.4rem 4rem 4rem !important;
    @include bp(sm-max) {
      padding: 2rem !important;
    }
    .empty-spacing {
      min-height: 28rem;
    }
    .modal-title {
      margin-bottom: 2.4rem;
    }
    .form-group {
      &.searchbar-wrap {
        .MuiOutlinedInput-root {
          .MuiAutocomplete-endAdornment {
            display: none;
          }
        }
      }
      .separator {
        border-bottom: 1px solid $grey2;
        margin: 1.2rem 0 2.4rem;
      }
      .MuiFormControl-root {
        .MuiInputBase-root {
          border-radius: 0.8rem;
          &.Mui-focused {
            border-radius: 0.4rem;
          }
        }
        .MuiAutocomplete-root {
          .MuiFormControl-root {
            .MuiInputBase-root {
              .MuiAutocomplete-endAdornment {
                .MuiButtonBase-root {
                  .MuiSvgIcon-root {
                    display: none !important;
                  }
                }
              }
            }
          }
        }
      }
      .date-picker-wrap {
        .MuiStack-root {
          padding: 0;
          .basic-date-picker {
            min-width: 15rem;
          }
        }
        background: $white;
        label {
          background: none;
        }
        textarea {
          padding-top: 1rem;
        }
        .MuiInputBase-root {
          fieldset {
            legend {
              span {
                background-color: transparent;
                display: none;
              }
            }
          }
          input {
            padding: 2.7rem 0 0.8rem 1.8rem !important;
          }
          .MuiButtonBase-root {
            margin-right: 0;
            width: 2.4rem;
            height: 2.4rem;
            .MuiSvgIcon-root {
              color: $black1;
              font-size: 2rem;
            }
          }
        }
        .MuiInputAdornment-root {
          .MuiButtonBase-root {
            width: 1.6rem;
            height: 1.6rem;
            .icon {
              &::before {
                font-size: 1.6rem;
                color: $black1;
              }
            }
          }
        }
      }
      .mt-4 {
        margin-top: 4rem;
      }
    }
    .added-bc-title-count {
      color: $trueGrey;
    }
    .chips-wrapper {
      &.list-items {
        margin: 0;
        ul {
          padding: 0;
          margin: 0;
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.96rem 1.2rem;
            border-bottom: 1px solid $grey18;
            > span {
              font-weight: 500;
            }
            > div {
              display: flex;
              align-items: center;
              .be-buildcard-box {
                margin-right: 0.6rem;
                margin-bottom: -0.3rem;
              }
            }
            &:last-child {
              border: 0;
            }
            span {
              display: inline-flex;
              align-items: center;
              span {
                font-weight: 500;
                @extend .truncated-text;
                max-width: 90%;
              }
            }
            .initials {
              height: 2.4rem;
              width: 2.4rem;
              min-width: 2.4rem;
              border-radius: 0.417rem;
              background: #f3ebff;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              font-size: 1.2rem;
              font-weight: 500;
              margin-right: 1.6rem;
            }
            .be-close {
              height: 0.9rem;
              line-height: 0.9rem;
              &::before {
                font-size: 1rem;
                height: inherit;
                color: $black;
                font-weight: bold !important;
              }
              &.be-multiple-users {
                &::before {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Status Dropdown [Select options] : [for - spaceGoals, goalDetailCard]
.status-menu-item {
  min-width: 14.5rem !important;
  border-bottom: 1px solid $grey2 !important;
  font-size: 1.4rem;
  @include line-height(14,20);
  padding: 0.8rem 1.6rem !important; 
  &:last-of-type {
    border: 0 !important;
  }
}
.status-dropdown-label {
  text-transform: lowercase;  
  &::first-letter {
    text-transform: capitalize !important;
  }
}