@import '../../assets/scss/colors';
@import '../../assets/scss/mixins';
@import '../../assets/scss/extended-classes';
@import '../../assets/scss/break-points';

// Supply chain distribution - common page for tabs
.supply-chain-distribution {
  .page-container {
    padding-right: 0;
    padding-bottom: 0;
    // .compnay-logo { padding-right: 4.8rem; }
    .page-top-navigation {
      ul li a {
        &:hover,
        &.active {
          text-shadow: none;
        }
      }
    }
  }
  section {
    display: flex;
    justify-content: space-between;
    @include bp(smd-max) {
      flex-direction: column;
    }

    // Left page content
    .page-content {
      padding-top: 2.4rem;
      width: 100%;
      max-width: calc(100% - 38.4rem);
      @include bp(smd-max) {
        max-width: calc(100% - 3rem);
        padding-bottom: 4.8rem;
        margin-bottom: 2.4rem;
      }
      .page-filters-wrapper {
        .left-filter {
          .count {
            font-size: 1.6rem;
            font-weight: 500;
            color: $black1;
          }
        }
        .right-filter {
          .be-box-arrow-down {
            margin-right: 0.8rem;
          }
          @include bp(smd-max) {
            flex-wrap: nowrap;
            justify-content: space-between;
          }
          @include bp(xs-max) {
            flex-wrap: wrap;
            justify-content: unset;
          }
          .form-group {
            @include bp(smd-max) {
              //width: 100%;
              width: calc(100% - 16rem);
              margin: 0 0 1.5rem;
              order: -1;
            }
            @include bp(xs-max) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.MuiPaper-root {
  &.three-dot-menu {
    min-width: 21.1rem;
    border-radius: 0.8rem;
    box-shadow:
      0px 1px 4px 0px rgba(0, 0, 0, 0.08),
      0px 4px 20px 0px rgba(0, 0, 0, 0.06);
    padding: 1.6rem;
  }

  .MuiList-root {
    .MuiButtonBase-root.toggle-menu-item-row {
      padding: 0.6rem 1.2rem;
      // &:first-child {
      //   margin-top: 1rem;
      // }
      &:hover {
        background: transparent;
      }
      .MuiButtonBase-root {
        border-radius: 3.2rem;
        font-size: 1.2rem;
        font-weight: 500;
        color: $black1;
        border: 1px solid $grey16;
        background: $grey15;
        @include line-height(12, 16);
        height: auto;
        padding: 0.2rem 0.8rem;
      }
    }
  }

  .toggle-menu-item-row {
    .icon,
    img {
      margin-right: 1.2rem;
    }
  }
}

// add members to space dialog
.add-members-to-space-dialog {
  @include custom-scroll;
  .modal-body {
    .modal-title {
      margin-bottom: 0.8rem;
      .right-title {
        text-align: right;
        .browse-files-link {
          font-size: 1.2rem;
          text-decoration: underline;
        }
      }
    }
    .members-to-be-added {
      font-size: 1.4rem;
      color: $black1;
      font-weight: 500;
      margin: 0 0 0.4rem;
      @include line-height(14, 24);
    }
    .no-members-text {
      font-size: 1.4rem;
      @include line-height(14, 20);
    }
    .bulk-member-upload-wrapper {
      min-height: auto;
      .member-upload-info {
        .upload-email-list-wrap {
          text-align: center;
          padding: 3.2rem 1.5rem 3.6rem;
          background: rgba(255, 255, 255, 0.7);
          border: 0.18rem dashed $grey9;
          border-radius: 0.4rem;
          h4 {
            margin-bottom: 0.4rem;
          }
          p {
            margin-bottom: 1.6rem;
          }
          .browse-files-link {
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
        }
        .uploaded-files-wrapper {
          padding-top: 1.6rem;
          .file-description {
            padding: 1.6rem 2rem;
            border-radius: 0px 0px 0px 2.4rem;
            border: 1px solid $grey8;
            display: inline-flex;
            align-items: center;
            margin: 0 0.8rem 0.8rem 0;
            .name {
              padding: 0 4.4rem 0 2rem;
            }
            .be-close {
              height: 1.6rem;
            }
          }
        }
        .member-links {
          margin-bottom: 0.8rem;
          align-items: baseline;
          .to-be-added-title {
            margin-top: 3.2rem;
          }
          .links {
            a {
              text-decoration: underline;
              font-size: 1.2rem;
              &:hover {
                text-decoration: none;
              }
            }
          }
          .imported-numbers {
            font-size: 1.6rem;
            color: $black1;
            max-width: 32rem;
            @include line-height(16, 22);
          }
        }
      }
    }
  }
  &.bulk {
    .modal-body {
      .modal-title {
        margin-bottom: 3.2rem;
      }
    }
  }
}

.MuiPopper-root.MuiAutocomplete-popper,
.base-Popper-root.MuiAutocomplete-popper {
  .MuiAutocomplete-option.Mui-focused {
    flex-wrap: wrap;
  }
  .search-members-dropdown,
  .search-spaces-dropdown {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .initials {
      height: 3.2rem;
      width: 3.2rem;
      min-width: 3.2rem;
      border-radius: 50%;
      background: $purple3;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 1.4rem;
      line-height: 1.33;
      font-weight: 600;
      color: $white;
      margin: 0 1.6rem 0 0;
      text-transform: uppercase;
      &.multiple-user-icon {
        height: 2.4rem;
        width: 2.4rem;
        min-width: 2.4rem;
      }
      .icon.be-user {
        height: 1.6rem;
      }
    }
    .name {
      font-size: 1.4rem;
      font-weight: 500;
      @include line-height(14, 20);
      letter-spacing: 0.014rem;
      color: $black1;
      margin: 0 0.8rem 0 0;
      &:empty {
        display: none;
      }
    }
    .email {
      color: $trueGrey;
      font-size: 1.4rem;
      font-weight: normal;
      //margin-left: .8rem;
    }
    .left-side {
      display: flex;
      align-items: center;
      .initials {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .user-details {
        max-width: 38rem;
        @extend .truncated-text;
      }
    }
    .right-side {
      display: block;
      //width: calc(100% - 4.2rem);
      flex-direction: column;
      align-items: flex-start;
      @extend .truncated-text;

      @include bp(sm-max) {
        display: block;
        width: calc(100% - 4.2rem);
        flex-direction: column;
        align-items: flex-start;
        @extend .truncated-text;
        .name {
          display: block;
        }
        .email {
          display: block;
        }
      }
    }
  }
  .search-spaces-dropdown {
    .left-side {
      .multiple-user-icon {
        &.initials {
          border-radius: 0.417rem;
          .icon {
            width: 1.6rem;
            height: 1.6rem;
            line-height: 1.6rem;
          }
        }
      }
    }
    .right-side {
      .MuiCheckbox-root {
        width: 3.2rem;
        min-width: 3.2rem;
        height: 3.2rem;
        padding: 0.6rem;
      }
    }
  }
  .MuiAutocomplete-option {
    padding: 0.8rem 1.6rem !important;
  }
}

.members-list-container {
  background: $white;
  padding: 0;
  margin-top: 1.6rem;
  max-height: 20rem;
  overflow: auto;
  @include custom-scroll;

  ul {
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.6rem 0;
      border-bottom: 1px solid $grey18;
      &:last-child {
        border-bottom: 0;
      }
      .user-link {
        display: inline-flex;
        align-items: center;
        font-size: 1.4rem;
        line-height: 1.4285714286;
        letter-spacing: 0.01em;
        font-weight: 500;
        color: $trueGrey;
        cursor: pointer;

        .initials,
        .user-avatar {
          @include user-avatar(1.2rem, 500, $purple2, $lightPurple, 3.6rem, 3.6rem, 3.6rem);
          .icon {
            &.be-user {
              &::before {
                font-weight: bold !important;
              }
            }
          }
          img {
            border-radius: 50%;
          }
        }
        .user-detail {
          max-width: 41rem;
          @extend .truncated-text;
          .name {
            color: $black1;
            padding-left: 0.8rem;
          }
          .email {
            color: inherit;
            margin-left: 0.8rem;
            font-weight: 400;
          }
        }
      }
      .be-close {
        margin-right: 1.6rem;
      }
    }
  }
}

.MuiFilledInput-root {
  &.search-tag-input {
    border: 0;
    padding: 0;
    .MuiInputBase-input {
      color: $black1;
      //padding-left: 0;
    }
  }
}
.space-list-table {
  .user-name {
    &.prime-color {
      color: $purple1 !important;
    }
  }
  .has-initials {
    .initials {
      margin-left: -0.5rem;
      margin-right: 0;
    }
  }
}
.MuiTableContainer-root {
  .MuiTable-root {
    .MuiTableHead-root {
      .be-down-arrow-1 {
        position: relative;
        top: 2px;
        left: 6px;
        font-size: 1.2rem;
      }
    }
    .MuiTableBody-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          .user-link {
            padding: 1rem 0;
            display: inline-flex;
            align-items: center;
            font-size: 1.4rem;
            letter-spacing: 0.01em;
            font-weight: 500;
            color: $black1;
            cursor: pointer;
            .initials,
            .user-avatar {
              @include user-avatar(1.4rem, 500, $black1, $lightPurple, 3.6rem, 3.6rem, 3.6rem);                
              .icon {
                &.be-user {
                  &::before {
                    font-weight: bold !important;
                  }
                }
              }
              img {
                border-radius: 50%;
              }
            }
            .user-name {
              padding-left: 1.2rem;
              color: $purple1;
            }
          }
          .be-info {
            cursor: pointer;
            position: relative;
            left: 6px;
            top: 3px;
          }
          .three-dots-btn {
            font-size: 2rem;
            margin: 0 -0.5rem 0 0.6rem;
            .be-vertical-dots {
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
}
.table-bulk-members-added {
  overflow-y: auto;
  @include custom-scroll;
  //max-height: 24rem;
  max-height: 10rem;

  .MuiTable-root {
    .MuiTableRow-root {
      td {
        border: 0;
      }
    }
  }
}
.be-close {
  cursor: pointer;
}

.search-tag-dropdown-menu {
  .MuiPaper-root {
    box-shadow: 0px 4px 16px 0px #00000026 !important;
  }
  .MuiMenu-list {
    max-height: 18rem;
    overflow: auto;
    @include custom-scroll;
    li {
      padding: 0.8rem 1.6rem;
      border-bottom: 1px solid $grey6;
      &:first-child {
        border-color: $grey12 !important;
        padding: 0.3rem 1.6rem 0 1.6rem;
      }
      &:last-child {
        margin-bottom: 0;
        border: 0;
      }
    }
    .MuiStack-root {
      flex-wrap: wrap;
      margin-bottom: 0;
      &.purple-chips {
        .MuiChip-root {
          background-color: $lightPurple;
          color: $black;
          font-weight: 500;
        }
      }
      .MuiChip-root {
        margin: 0;
        max-width: fit-content;
        background-color: $lightPurple;
        height: 2.4rem;
        font-size: 1.2rem;
        line-height: 2.4rem;
        border: 1px solid transparent;
        .MuiChip-label {
          font-weight: 500;
          font-size: 1.2rem;
          color: $black1;
          max-width: 20rem;
          display: inline-flex;
          align-items: center;
        }
        &:hover {
          background: $lightPurple;
          border: 1px solid $purple2;
        }
      }
    }
  }
}
