// Tabs Menu, Page top nav

.projects-top-nav-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  @include bp(sm-max) {
    flex-wrap: wrap;
  }
  .page-top-navigation {
    width: calc(100% + 2.4rem);
    @include bp(sm-max) {
      width: calc(100% + 2rem);
    }
  }
  .page-filters-wrapper {
    position: absolute;
    right: 0;
    bottom: 1.6rem;
    padding-right: 2.5rem !important;
    .purple-btn {
      font-size: 1.4rem;
    }
  }
}

.page-top-navigation {
  border-bottom: 0.1rem solid $grey3;
  margin: 0 -2.4rem;
  padding: 0 2.4rem;
  white-space: nowrap;
  height: 3.6rem;
  overflow: hidden;
  @include bp(lg-max) {
    margin: 0 -2.4rem;
    padding: 0 2.4rem;
  }
  @include bp(lg-max) {
    margin: 0 -2rem;
    padding: 0 2rem;
  }
  ul {
    @extend .no-bullets;
    display: flex;
    padding-bottom: 20px;
    overflow: auto;
    li {
      margin-right: 4.8rem;
      @include bp(smd-max) {
        margin-right: 2.4rem;
      }
      &:last-child {
        margin-right: 0;
      }
      .link-btn {
        font-size: 1.6rem;
        line-height: 1.75;
        color: $trueGrey;
        padding-bottom: 0.7rem;
        font-weight: 400;
        display: inline-block;
        position: relative;
        padding-right: 1.6rem;
        cursor: pointer;
        &::after {
          content: '\4e';
          font-family: $enterpriseFont;
          position: absolute;
          right: 0;
          top: calc(50% - 0.7rem);
          height: 0.8rem;
          width: 0.8rem;
          font-size: 0.8rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
        }
      }
      a {
        position: relative;
        font-size: 1.6rem;
        @include line-height(16, 28);
        color: $black1;
        padding-bottom: 0.7rem;
        font-weight: 400;
        &:hover,
        &.active {
          color: $purple1;
          font-weight: 500;
          &::after {
            left: 0;
            right: 0;
            width: 100%;
          }
        }
        &:hover:not(.active) {
          font-weight: 400;
        }
        &:after {
          content: '';
          position: absolute;
          left: 50%;
          right: 50%;
          bottom: 0;
          width: 0;
          height: 0.2rem;
          background-color: $purple1;
          @extend .transition;
        }
      }
    }
  }
}

.tabs-menu.page-top-navigation {
  height: 4rem !important;
  @extend .tab-menu;
}
