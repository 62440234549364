.integrations-page {
  .main-page-header {
    .page-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .link {
        font-size: 1.4rem;
        line-height: 2rem;
        font-weight: 500;
      }
    }
  }
  .separator {
    //border: 0;    
    margin-left: -2.4rem;
    margin-right: -2.4rem;
    margin-bottom: 2.4rem;
    border-bottom: 1px solid $grey2;
  }
  .integration-dashboard {
    .dashboard-title-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 3.2rem;
      @include bp(sm-max) {
        flex-wrap: wrap;
        margin-bottom: 0.8rem;
      }
      h4 {
        margin: 0;
        line-height: 2.8rem;
      }
      .right-title {
        display: flex;
        align-items: center;
        .page-filters-wrapper {
          margin: 0;
          margin-left: 1.6rem;
        }        
        @include bp(sm-max) {
          .switch-with-label {
            label {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  .integraion-card-wrapper {
    margin-bottom: 4.8rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .integration-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.2rem -2.4rem;
    .integration-col {
      width: 25%;
      padding: 0 1.2rem 2.4rem;
      @include bp(smd-max) {
        width: 50%;
      }
      @include bp(sm-max) {
        width: 100%;
      }
    }
  }

  .integration-card {
    cursor: pointer;
    border: 1px solid $grey2;
    border-radius: 0 0 0 2rem;
    padding: 2.4rem 2.4rem 4.8rem;
    height: 100%;
    @extend .transition;
    position: relative;
    background-color: $white;
    &:hover {
      border-color: $purple;
      box-shadow: 0 0.4rem 1.6rem 0 rgba($black, 0.15);
      background-color: transparent;
    }
    &.active {
      background-color: transparent;
    }
    &.filled {
      background-color: $purple1;
      padding: 1.6rem 3.7rem 1.6rem 2.4rem;
      box-shadow: 0 0.2rem 0.4rem 0 rgba($black, 0.15);
      border-color: $purple1;
      &:hover {
        border-color: $purple1;
      }
      p {
        color: $white;
      }
    }
    .tag-container {
      position: absolute;
      right: 2.4rem;
      top: 2.4rem;
    }
    .boxed-title-block {
      margin: 0 0 1.6rem -0.8rem;
    }
    .icon {
      display: inline-flex;
      height: 4.8rem;
      width: 4.8rem;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.6rem;
      img {
        max-height: 100%;
      }
    }
    h5 {
      margin-bottom: 0.8rem;
    }
    p {
      color: $trueGrey;
      margin-bottom: 1.6rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .MuiButton-root {
      width: 100%;
      &.white-btn {
        color: $black1;
      }
    }
  }

  .integration-detail-page {
    padding: 1.6rem 14.5rem 0 0;
    @include bp(sm-max) {
      padding-right: 1.6rem;
    }
    .detail-wrapper {
      display: flex;
      padding-bottom: 10rem;
      @include bp(sm-max) {
        flex-wrap: wrap;
      }
      .img-col {
        width: 17.2rem;
        @include bp(sm-max) {
          width: 100%;
        }
        .company-logo {
          width: 17.2rem;
          height: 17.2rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border: 0.1rem solid $purple6;
          border-radius: 0.8rem;
          background-color: $white;
          img {
            width: 8.8rem;
            height: auto;
          }
        }
      }
      .detail-col {
        width: calc(100% - 17.2rem);
        padding-left: 4.8rem;
        @include bp(sm-max) {
          width: 100%;
          padding-left: 0;
          padding-top: 2rem;
        }
        .detail-title {
          margin-bottom: 1.6rem;
          .title-outer {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 1.6rem;
            .app-name {
              margin: 0;
              margin-right: 1.6rem;
            }
          }
        }
        .app-content {
          .content-outer {
            margin-bottom: 3.2rem;
            &:last-child {
              margin-bottom: 0;
            }
            h6 {
              margin-bottom: 0.8rem;
            }
            ul {
              margin: 0 0 2rem;
              padding-left: 3rem;
              &:last-child {
                margin: 0;
              }
              li {
                margin-bottom: 1rem;
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
        > div > div {
          color: $black1;
          p {            
            font-size: 1.6rem;
            @include line-height(16,24);
            margin: 0 0 2.8rem 0;
          }
          h4 {
            font-size: 1.8rem;
            @include line-height(18,28);
            font-weight: 500;
            margin: 0 0 .8rem 0;
          }
          ul {
            padding: 0 2.8rem 3.2rem 2.8rem;
            margin: 0;
            li {
              list-style-type: disc;
              font-size: 1.6rem;
              line-height: 4rem;
            }
          }
        }
      }
    }
    .bottom-fixed-btn {
      position: fixed;
      left: 5.6rem;
      right: 0;
      bottom: 0;
      z-index: 5;
      padding: 2.2rem 4.1rem;
      border-top: 0.1rem solid rgba(222, 214, 241, 0.5);
      background: $white;
      display: flex;
      justify-content: flex-end;
      @include bp(sm-max) {
        left: 0;
      }
      .MuiButton-root {
        min-width: 18.5rem;
      }
    }
  }
}

.make-suggestion-modal {
  input, 
  textarea, ::placeholder {
    font-size: 1.6rem !important; 
  }
  textarea {
    padding-right: 4.8rem;
  }
  .modal-form {
    .form-group {
      position: relative;
    }
    .count {
      color: $grey11;
      text-align: right;
      font-size: 1.2rem;
      font-weight: 400;
      @include line-height(12,16);
      letter-spacing: 0.012rem;
      position: absolute;
      right: 1.6rem;
      top: 5.1rem;
      background: #fff;
      z-index: 1;
      @include bp(xsm-max) {
        top: auto;
        bottom: 9.2rem;
      }
    }
  }  
}