@import '../../../assets/scss/colors';
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/break-points';
@import '../../../assets/scss/extended-classes';
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap');

.filterList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: auto;

  list-style: none;
  margin: 3rem -1.2rem 0;
  padding: 0;
  @include bp(sm-max) {
    margin-top: 0;
  }
  li {
    width: 25%;
    padding: 0 1.2rem 3rem;
    @include bp(lg-max) {
      width: 33.33%;
    }
    @include bp(smd-max) {
      width: 50%;
    }
    @include bp(sm-max) {
      width: 100%;
    }

    .catalog-card {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      height: 100%;
      padding: 1.5rem 1.5rem 0;
      background: $white;
      border: 1px solid $grey2;
      cursor: pointer;
      position: relative;
      transition: all 0.3s ease-in;
      border-radius: 0 0 0 2rem;
      .catalog-head {
        position: relative;
        display: flex;
        justify-content: space-between;
        span {
          color: $grey11;
          font-size: 1rem;
          font-weight: 500;
          letter-spacing: 0.1rem;
          display: block;
          padding: 1.6rem 0 0.2rem;
          text-transform: uppercase;
        }
        h3 {
          color: $black1;
          font-size: 1.8rem;
          font-weight: 500;
          margin-bottom: 0.3rem;
        }
        .plus-icon {
          width: 4rem;
          height: 4rem;
          border: 0.1rem solid $grey2;
          background-color: $grey7;
          color: $black2;
          border-radius: 0.6rem;
          position: absolute;
          right: 0.2rem;
          top: 0.2rem;
          margin-right: 0;
          font-size: 2.4rem;
          font-weight: 300;
        }
        .brand-iconbx {
          width: 5.6rem;
          height: 5.6rem;
          border-radius: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            border-radius: inherit
          }
        }
        .title {
          width: 80%;
        }
        .is-icon-btn.three {
          width: 3rem;
          height: 3rem;
          background: transparent;
          em {
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .middle {
        min-height: 10rem;
      }
      .catalog-footer {
        border-bottom: 0;
        padding: 1rem 4rem 1rem 1.5rem;
        margin: 1rem -1.5rem 0;
        border-radius: 0 0 0 2rem;
        border-top: 0.1rem solid $grey2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .actionbx {
          margin-right: 3rem;
          span {
            color: $grey5;
            font-size: 1rem;
            font-weight: 500;
            letter-spacing: 0.1rem;
            display: block;
            text-transform: uppercase;
          }

          h5 {
            color: $black1;
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 2.4rem;
            margin-bottom: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      p {
        color: $trueGrey;
        font-size: 1.4rem;
        line-height: 20px;
        margin-bottom: 0.5rem;
      }
      &:hover {
        border-color: $purple2;
        box-shadow: 0 0 2rem #00000014;
      }
    }
  }
}

.catalog-page {
  .tab-contest {
    .MuiButtonBase-root {
      padding-left: 0;
      padding-right: 0;
      max-width: inherit;
      min-width: inherit;
      margin-right: 3.5rem;
      text-transform: inherit;
      font-size: 1.6rem;
    }
  }
  .catalog-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  .page-filters-wrapper {
    margin-top: 0.5rem;
  }
  .page-title {
    @include bp(sm-max) {
      flex-wrap: wrap;
    }
  }
}

.catalog-detail-dialog {
  .MuiButtonBase-root {
    padding: 0.8rem 1.2rem;
    max-width: inherit;
    min-width: initial;
    margin-right: 0;
    text-transform: inherit;
    font-size: 1.4rem;
    min-height: 3.6rem;
  }
  .MuiTabs-flexContainer {
    background-color: $grey13;
    display: inline-block;
    border-radius: 0.8rem;
    margin-top: 1rem;
    .Mui-selected {
      background-color: $lightPurple;
      border-radius: 0.8rem;
    }
  }
  .MuiTabs-indicator {
    display: none;
  }
  .MuiBox-root {
    border: 0;
  }
  .close-menu {
    position: absolute;
    right: 2rem;
    top: 1.7rem;
    font-size: 1.8rem;
  }
  .MuiDialog-container {
    .MuiDialog-paper {
      width: 120rem !important;
      padding: 6rem 6rem 4rem;
    }
    .catalog-modal-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 !important;
      .titleRow {
        display: flex;
        width: 100%;
        margin: 0;
        align-items: flex-start;
        .thumbIcon {
          width: 56px;
          height: 56px;
          margin: 0 1.5rem 0 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 1rem;
          img {
            border-radius: inherit;
          }
        }
        h2 {
          font-size: 2.4rem;
          line-height: 2.6rem;
          span {
            display: block;
            font-size: 1.4rem;
            color: $black1;
            font-weight: 400;
          }
        }
      }
      .catalog-modal-footer {
        border-bottom: 0;
        padding: 0.5rem 1.5rem;
        margin: 0rem -1.5rem 1.5rem;
        border-radius: 0 0 0 2rem;

        display: flex;
        align-items: center;
        .actionbx {
          margin-right: 1.6rem;
          border-right: 1px solid $grey2;
          padding-right: 1.6rem;

          span {
            color: $grey4;
            font-size: 1.2rem;
            font-weight: 400;
            letter-spacing: 0.1rem;
            display: block;
            text-transform: uppercase;
            margin-bottom: 0.5rem;
            .be-info {
              font-size: 1.1rem;
              position: relative;
              top: 0.1rem;
            }
          }
          h5 {
            color: $black1;
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 2.4rem;
            margin-bottom: 0;
          }
          &:last-child {
            margin-right: 0;
            padding-right: 0;
            border: 0;
          }
        }
      }
      .catalog-table {
        .catalog-table-head {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid $grey2;
          position: sticky;
          top: 0;
          background-color: $white;
          .table-column {
            font-size: 1.2rem;
            line-height: 1.6rem;
            padding: 1rem 1.4rem 0.8rem 1.4rem;
            text-transform: uppercase;
            color: $black1;
            letter-spacing: 0.1rem;
            width: 36%;
            font-weight: 500;
          }
        }
        .table-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .table-column-left {
            padding: 0.8rem 1.4rem;
            display: flex;
            align-items: center;
            .count {
              width: 2rem;
              height: 2rem;
              font-size: 1rem;
              color: $white;
              display: flex;
              align-items: center;
              justify-content: center;
              background: $grey5;
              margin: 0 0.8rem 0 0;
              border-radius: 2rem;
              line-height: 2rem;
            }
            .img {
              width: 3.2rem;
              height: 3.2rem;
              border-radius: 0.4rem;
              display: inline-block;
              margin-right: 0.8rem;
              background-color: $lightPurple;
            }
          }
          .table-column-right {
            width: 33.5%;
            h3 {
              font-size: 1.4rem;
            }
          }
          h3 {
            color: $black;
            font-size: 1.4rem;
            font-weight: 600;
            margin-bottom: 0;
          }
          p {
            font-size: 1.4rem;
            color: $black1;
            font-weight: 400;
          }
          &.sel {
            background-color: $lightPurple1;
            .table-column-left {
              padding: 1.2rem 1.4rem;
            }
          }
        }

        .table-bd {
          padding-bottom: 0;
          margin-bottom: 0.2rem;
          &:last-child {
            border: 0;
            margin-bottom: 0;
            padding-bottom: 0;
          }
        }
        &.marketplace-table {
          .table-column {
            width: 23% !important;
          }
          .table-column-left {
            width: 20%;
          }
          .table-column-right {
            text-align: center;
          }
          .service-icon {
            width: 3.2rem;
            height: 3.2rem;
            padding-right: 0.4rem;
            border-radius: 0.4rem;
            img {
              border-radius: inherit;
            }
          }
        }
      }
      .description {
        font-size: 1.6rem;
        color: $black1;
        line-height: 2.8rem;
        margin-bottom: 2.5rem;
      }
      .page-top-navigation {
        margin-top: 25px;
        margin-bottom: 24px;
        border: 0;
      }
      .catalog-wrapper {
        padding: 1.5rem 0 0 !important;
      }
    }
  }
  .catalog-content-left {
    width: 71%;
    p {
      font-size: 1.4rem;
      line-height: 2rem;
      color: $black1;
      letter-spacing: 0.02rem;
      // margin: 0.5rem 0 0.8rem;
    }
    .cost-feature-row {
      p {
        margin: 1rem 0;
      }
    }
  }
  .catalog-content-right {
    width: 28%;
    text-align: right;
    position: relative;
    margin: 3rem 0 0;
    @include bp(xlg-max) {
      margin: 0;
    }
    .image-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4.5rem;
      padding: 1rem;    
    }
    img {
      &.splash {
        border: 3px solid #EBEDF0;
        outline: 8px solid #C2C7D0;
      }
      border-radius: inherit;      
      @include bp(xl-max) {
        max-height: 50rem;
      }            
    }
  }
  .catalog-tab-content {
    max-height: 37.8rem;
    overflow-y: auto;
    @include custom-scroll;
    @include bp(xl-max) {
      max-height: 28rem;
    }
  }
}
.three-dot-menu-paper {
  .icon {
    img {
      margin-right: 0 !important;
    }
  }
}

.image-crop-container {
  max-width: 300px;
  max-height: 300px;
  overflow: hidden;
  
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.scale-slider {
  margin-top: 1rem;
  width: 200px;
}

.ReactCrop {
  max-width: 300px;
  max-height: 300px;
  
  &__crop-selection {
    border-radius: 50%;
  }
}
